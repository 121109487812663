$(function () {
  // Scroll Hint
  new ScrollHint('.table-scroll-wrapper', {
    i18n: {
      scrollable: 'スクロールできます',
    },
  });
});

// 緊急のお知らせ
(function () {
  const elements = document.querySelectorAll('.urgent-notice');

  Array.from(elements).forEach(function (el) {
    //ボタンを取得
    const btn = el.querySelector('.urgent-notice__switch');
    const txt = el.querySelector('.urgent-text');
    //コンテンツを取得
    const content = el.querySelector('.urgent-notice__content');

    btn.addEventListener('click', function () {
      if (!content.classList.contains('open')) {
        content.style.maxHeight = content.scrollHeight + 'px';
        content.classList.add('open');
        txt.textContent = 'とじる';
      } else {
        content.style.maxHeight = '';
        content.classList.remove('open');
        txt.textContent = 'もっと見る';
      }
    });
  });
})();
